@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Sen", sans-serif !important;
  background-color: #f0f2f5;
  @apply text-backdrop;
}

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply my-0;
    @apply mb-2;
  }
  p {
    @apply my-0;
    @apply leading-normal;
  }

  h1 {
    @apply text-2xl font-extrabold leading-6;
  }

  h2 {
    @apply text-xl font-extrabold;
  }

  h3 {
    @apply text-lg font-bold leading-[38px];
  }

  h4 {
    @apply text-base font-bold leading-[37px];
  }

  h5 {
    @apply text-base font-normal;
  }
}

.basic-card-small {
  @apply rounded-[10px] shadow-custom py-[13px] px-[17px];
}

.basic-card {
  @apply rounded-[10px] shadow-custom py-5 px-6;
}

.basic-tag {
  @apply rounded-[5px] py-[5px] px-[7px] shadow-custom border-[0.5px] text-[12px] font-normal leading-[14px];
}

.basic-tag-white {
  @apply basic-tag bg-white border-dirtyBlue;
}

.ant-menu-item-selected {
  @apply !text-backdrop !bg-primary-bg;
}

.ant-menu-item-selected::after {
  margin: 15px 0px !important;
  @apply !border-b-primary;
}

.ant-menu-item-active::after {
  margin: 15px 0px !important;

  @apply !border-b-primary;
}

.list-outline-backdrop li.custom-item::before {
  content: "";
  @apply border-backdrop rounded-full h-1 w-1 mr-6 border border-solid;
}

.green-header-table .ant-table-thead > tr > th {
  @apply bg-primary-bg;
}

div.ant-timeline-item-label {
  width: calc(30% - 12px) !important;
}

div.ant-timeline-item-content {
  left: calc(31% - 4px) !important;
  width: calc(59% - 4px) !important;
}

div.ant-timeline-item-tail,
div.ant-timeline-item-head {
  left: 31% !important;
}

a {
  @apply text-backdrop;
}

a:hover {
  @apply text-backdrop;
}

.points-table .ant-table-title {
  @apply bg-[#FAFAFA];
}

.ant-pagination-item-active a {
  @apply bg-[#36505E] !text-white;
}

.ant-pagination-item-active {
  @apply !border-[#36505E];
}

.footnote {
  @apply text-[#919191] text-[12px];
}

.custom-yellow-header
  .ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  @apply bg-secondaryYellow;
}

.transparent-table {
  @apply bg-transparent;
}

.transparent-table .ant-table {
  @apply bg-transparent;
}

.transparent-table thead {
  border-bottom: 1px solid #d9d9d9;
}

.transparent-table
  .ant-table
  .ant-table-container
  .ant-table-content
  table
  tbody {
  @apply bg-transparent bg-none;
  background: none !important;
}

.square-navigation .ant-tabs-nav-list {
  @apply w-full;
}

.square-navigation .ant-tabs-tab {
  @apply flex-grow items-center w-full justify-center !border !border-backdrop;
}

.square-navigation .ant-tabs-tab-active {
  @apply bg-primary-bg;
}

.ant-slider-handle::after {
  border: 1px solid black;
  background-color: #d4f5c5 !important;
}

.cv .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #d9d9d9;
}

.modal-billing > .ant-modal-content {
  background-color: #fafafa !important;
}

.golden-subtitle {
  @apply text-[10px] tracking-widest text-brown font-light uppercase;
}

.bg-grey-select > .ant-select > .ant-select-selector {
  background-color: #d9d9d9 !important;
}

.rounded-modal .ant-modal-content {
  @apply !rounded-[11px];
}

.select-text-backdrop .ant-select-selection-placeholder {
  @apply !text-backdrop;
}

.select-green .ant-select > .ant-select-selector {
  @apply !bg-primary;
  @apply !text-white;
  @apply !border-none;
}

.select-green .ant-select > .ant-select-arrow {
  @apply !text-white;
}

/* Following styles apply ant design menu inside sidebar */
.ant-menu-item:hover,
.ant-menu-submenu-title:hover {
  @apply !bg-slate-500 !text-white;
}

.ant-menu.ant-menu-sub.ant-menu-vertical
  > .ant-menu-item.ant-menu-item-only-child
  > span.ant-menu-title-content {
  @apply !text-backdrop;
}

.custom > .ant-menu-submenu > .ant-menu-submenu-title {
  @apply !text-backdrop;
}
.custom
  > .ant-menu-submenu
  > .ant-menu.ant-menu-sub.ant-menu-inline
  > .ant-menu-item.ant-menu-item-only-child
  > .ant-menu-title-content {
  @apply !text-backdrop;
}

.custom > .ant-menu-item.ant-menu-item-only-child {
  @apply !text-backdrop;
}

/* Upload Image Ant Design */
.ant-upload-picture-card-wrapper.custom-picture-upload {
  @apply h-full w-[134px];
}
.ant-upload-picture-card-wrapper.custom-picture-upload
  .ant-upload.ant-upload-select {
  @apply h-full m-0 w-[134px] rounded;
}

.hide-select .ant-select > .ant-select-selector {
  border: none;
  background-color: #fafafa;
}
